@import '@uidd/adel-amp-next/dist/themes/imports/amp.next/_breakpoints.css';

.careers-scope {
  & .HeroTopicFront-component {
    data-heading,
    data-body,
    data-image,
    data-text-alignment,
    data-color,
    data-large-facet,
    data-mobile-facet,
    data-button-alignment,
    data-button-href,
    data-button-target,
    data-button-text,
    data-analytics {
      display: none;
    }
  }

  & .Hero-topic-front {
    background-repeat: no-repeat;
    background-position: 50%;
    min-height: 250px;
  }

  & .HeroTopicFront--redesign {
    & .HeroTopicFront-bgImage {
      width: 100%;
      height: 100%;
      object-fit: cover;
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 50%);
    }

    & .HeroTopicFront-contentBox {
      min-height: 300px;
    }

    & .HeroTopicFront-contentBox p,
    a {
      color: #fff;
    }

    /* Mobile */
    @media (max-width: 767px) {
      & .HeroTopicFront-contentBox {
        min-height: 250px;
      }
    }

    & .HeroTopicFront-contentImage img {
      height: 100%;
      object-fit: cover;
    }
  }
}
