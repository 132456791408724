@import '@uidd/adel-amp-next/dist/themes/imports/amp.next/_breakpoints.css';

.careers-scope {
  .component-wrapper {
    data-body,
    data-facet-color,
    data-hide-mobile-image,
    data-text-alignment,
    data-component-spacing,
    data-heading,
    data-image,
    data-image-mobile {
      display: none;
    }
  }

  & .HeroSectionFront {
    position: relative;
    & .HeroSectionFront-facet {
      position: absolute;
      z-index: -1;
      width: 100%;
      object-fit: cover;

      @media (max-width: 767px) {
        clip-path: polygon(0 0, 100% 0, 100% 98%, 0 69%);
        height: 100%;
      }

      @media screen and (min-width: 768px) and (max-width: 1024px) {
        clip-path: polygon(0 0, 100% 0, 100% 98%, 0 50%);
        height: 100%;
      }

      @media (min-width: 1025px) {
        clip-path: polygon(0 0, 100% 0, 100% 98%, 0 50%);
        height: 110%;
      }
    }

    & .HeroSectionFront-content {
      position: relative;
      & .Content {
        p {
          font-size: 1.25rem !important;
        }
      }

      /* tablet/desktop */
      & .HeroSectionFront-featuredImage {
        @media (max-width: 767px) {
          height: 250px !important;
          object-fit: cover;
        }
      }
    }
  }
}
