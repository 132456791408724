@import '@uidd/adel-amp-next/dist/themes/imports/amp.next/_breakpoints.css';

.careers-scope {
  & .ComplexHero-container {
    & data-content,
    & data-analytics,
    & data-featured-image,
    & data-background-image,
    & data-component-spacing,
    & data-featured-image-mobile {
      display: none;
    }
    // TODO: remove code after CSS is added by design system.
    & .LoginClient {
      & a {
        &:focus {
          outline-color: var(--color-action-blue);
        }
        &.u-textReversed,
        &.Link--reversed {
          //Links with u-textReversed should be updated to have class Link--reversed
          &:focus {
            outline-color: #fff;
          }
        }
      }

      & .Button--secondary--action-blue {
        &:focus:not(:disabled) {
          color: var(--color-action-blue);
          outline-color: var(--color-action-blue) !important;
        }
        &.Button--reversed {
          &:focus:not(:disabled) {
            color: #fff;
            outline-color: #fff !important;
          }
        }
      }
    }

    & .ComplexHero {
      & p {
        font-size: 1.25rem;
        line-height: 1.3;
      }
      // TODO: Override. Check if next adel upgrade resolves color issue.
      & .u-borderColorNeutralHero {
        border: solid 1px var(--color-stone-dark);
      }

      & .ComplexHero-featuredImage {
        width: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: bottom center;
      }

      & .ComplexHero-topWrapper {
        height: 300px;
      }

      & .ComplexHero-background {
        z-index: -1;
        top: 0;
        background-size: cover;
        background-position: center center;
        width: 100%;
      }

      /* mobile/tablet */
      @media screen and (max-width: 1024px) {
        & .ComplexHero-background,
        & .ComplexHero-topWrapper {
          height: 300px;
        }

        & .ComplexHero-mobileOverlay {
          background-color: #fff;
        }

        & .ComplexHero-content {
          border: solid 1px var(--color-stone-dark);
          margin-top: -50px;
          background-color: #fff;
        }

        & .ComplexHero-featuredImage {
          margin-top: calc(var(--grid-unit) * 3);
          height: calc(300px - calc(var(--grid-unit) * 3));
        }

        &.ComplexHero--illustration {
          & .ComplexHero-featuredImage {
            background-size: auto calc(250px - var(--grid-unit) * 3);
            background-position: center top;
          }
        }
        &.ComplexHero--facetCutout {
          & .ComplexHero-featuredImage {
            height: calc(300px - calc(var(--grid-unit) * 6));
            background-size: auto 100%;
            margin-bottom: -50px;
            clip-path: polygon(
              0 0,
              100% 0,
              100% calc(100% - calc(50px - var(--grid-unit) * 3)),
              0 calc(100% - calc(50px - var(--grid-unit) * 3))
            );
          }
        }
      }

      /* Desktop */
      @media screen and (min-width: 1025px) {
        height: 400px;
        // TODO: need u-md-sizeConstrainedFlush in ADEL in order to remove container width styles
        margin-left: auto;
        margin-right: auto;
        max-width: 1240px;
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;

        & .ComplexHero-background,
        & .ComplexHero-topWrapper {
          height: 400px;
        }

        & .ComplexHero-mobileOverlay {
          align-items: center;
          display: flex;
        }

        & .ComplexHero-border {
          border: solid 1px var(--color-stone-dark);
          border-top: none;
        }

        & .ComplexHero-featuredImage {
          margin-top: calc(var(--grid-unit) * 3);
          margin-right: calc(var(--grid-unit) * 3);
          height: calc(100% - var(--grid-unit) * 3);
          background-position: center center;
        }

        & .ComplexHero-content {
          max-height: 400px;
          overflow-y: hidden;
        }

        &.ComplexHero--imageOverlay {
          & .ComplexHero-content {
            margin-top: calc(var(--grid-unit) * 4);
            margin-bottom: calc(var(--grid-unit) * 4);
          }
        }
      }
    }
  }
}
